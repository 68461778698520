<template>
  <div>
    <div>
      <!-- <div class="trash-icon"> -->
      <feather-icon
        v-if="isStaff"
        v-b-tooltip.hover.bottom
        icon="Trash2Icon"
        :title="$t('unshare.tooltip')"
        size="20"
        style="cursor:pointer"
        class="text-primary float-right ml-1"
        @click="isUnshareModalVisible = true"
      />
      <!-- </div> -->
      <feather-icon
        v-if="isStaff"
        v-b-modal.modal-edit
        icon="Edit2Icon"
        size="20"
        class="text-primary edit-icon float-right"
        role="button"
      />
    </div>
    <unshare-modal
      v-model="isUnshareModalVisible"
      model-type="people"
      @remove-item="handleRemoveItem"
    />
    <b-img
      v-if="speaker.organizationLogoURL"
      ref="refPreviewEl"
      :src="logoSrc || logoPlaceholder"
      class="float-right logo"
    />
    <div class="d-md-flex align-items-center m-2">
      <!--Left side-->
      <div>
        <!--Avatar-->
        <b-img
          ref="refPreviewEl"
          :src="avatarSrc || placeholder"
          class="avatar"
          :style="!speaker.isPublished ? 'filter: grayscale(100%);' : ''"
        />
      </div>
      <!--Right side-->
      <div class="mt-1 ml-0 ml-md-2">
        <b-card-title class="m-0 text-center text-md-left">
          {{ speaker.name }} {{ speaker.surname }}
        </b-card-title>
        <h5 class="d-flex flex-column text-muted text-center text-md-left">
          <span v-if="headline">{{ headline }} </span>

          <span
            v-if="jobTitle"
          >{{ jobTitle }}
            <span
              v-if="speaker.organization"
            >({{ speaker.organization }})</span></span>
          <span
            v-if="speaker.organization && !jobTitle"
          >{{ speaker.organization }}
          </span>
        </h5>
        <!-- Links -->
        <div v-if="speaker.links" class="d-flex">
          <p v-for="(link, index) in speaker.links" :key="index">
            <a
              :href="link.url"
              target="_blank"
              class="social-link align-text-top"
            >
              <feather-icon
                size="18"
                :icon="
                  hasSocialIcon(link.site) ? `${link.site}Icon` : 'GlobeIcon'
                "
              />
            </a>
          </p>
        </div>
      </div>
    </div>
    <!-- eslint-disable-next-line vue/no-v-html-->
    <b-card-text class="mt-2 mt-md-1 mx-2" v-html="description" />
    <!--Ponencias carousel-->
    <div class="col-md-12 my-3">
      <b-card
        v-if="eventsNumber > 0"
        :title="$t('events.title')"
        class="horizontal-content"
      >
        <events-horizontal-list :can-view="true" :people-key="speaker.key" />
      </b-card>
    </div>
    <!-- Edit people -->
    <b-modal
      id="modal-edit"
      :title="$t('people.edit-people', { type: translatedType })"
      :ok-title="$t('form.actions.save')"
      :cancel-title="$t('form-create-item.cancel')"
      cancel-variant="outline-secondary"
      centered
      size="lg"
      @ok="savePeople"
    >
      <b-form>
        <b-row>
          <b-col cols="12" md="6">
            <b-form-group>
              <label for="name" class="font-weight-bold">
                {{ $t("people.name") }}*
              </label>
              <b-form-input id="name" v-model="person.name" type="text" />
            </b-form-group>
          </b-col>
          <b-col cols="12" md="6">
            <b-form-group>
              <label for="surnmame" class="font-weight-bold">
                {{ $t("people.surname") }}
              </label>
              <b-form-input id="surname" v-model="person.surname" type="text" />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" md="6">
            <b-form-group v-if="person.jobTitle !== null">
              <label for="job" class="font-weight-bold">
                {{ $t("people.job-title") }}:
              </label>
              <b-form-input
                id="job"
                v-model="person.jobTitle[locale]"
                type="text"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12" md="6">
            <b-form-group>
              <label for="organization" class="font-weight-bold">
                {{ $t("people.organization") }}:
              </label>
              <b-form-input
                id="organization"
                v-model="person.organization"
                type="text"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" md="6">
            <b-form-group>
              <label for="email" class="font-weight-bold">
                Email
              </label>
              <b-form-input id="email" v-model="person.emails[0].email" type="text" />
            </b-form-group>
          </b-col>
          <b-col cols="12" md="6">
            <label for="type" class="font-weight-bold">
              {{ $t("people.type") }}*
            </label>
            <v-select
              v-model="noKey"
              name="classifier-filter"
              :clearable="false"
              :searchable="false"
              label="name"
              :options="types"
              class="mb-2"
            />
          </b-col>
          <b-col cols="12">
            <label for="published" class="font-weight-bold">
              {{ $t("people.published") }}
            </label>
            <b-form-checkbox v-model="published" switch />
          </b-col>
        </b-row>
        <b-form-group v-if="person != null && person.description !== [] || person.description != null">
          <label
            class="mt-2"
            for="description"
          >
            {{ $t("form-create-item.description") }}:
          </label>
          <quill-editor v-if="person.description != null" v-model="person.description[locale]" />
        </b-form-group>
        <!-- Photo -->
        <label class="font-weight-bold mt-1"> {{ $t("people.photo") }}: </label>
        <b-media no-body vertical-align="center">
          <b-media-body>
            <b-form-file
              v-model="imageURL"
              class="pointer"
              :placeholder="$t('form-create-item.image-placeholder')"
              :browse-text="$t('form-create-item.image-button')"
            />
            <b-img
              ref="refPreviewEl"
              :src="avatarSrc || placeholder"
              class="d-block mx-auto"
              style="max-width: 150px;"
            />
          </b-media-body>
        </b-media>
        <!-- Logo -->
        <label class="font-weight-bold mt-1">
          {{ $t("people.logo") }}
        </label>
        <b-media no-body vertical-align="center">
          <b-media-body>
            <b-form-file
              v-model="organizationLogoURL"
              class="pointer"
              :placeholder="$t('form-create-item.image-placeholder')"
              :browse-text="$t('form-create-item.image-button')"
            />
            <b-img
              ref="refPreviewEl"
              :src="logoSrc || logoPlaceholder"
              class="d-block mx-auto"
              style="max-width: 150px;"
            />
          </b-media-body>
        </b-media>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import { translateTranslationTable } from '@core/libs/i18n/utils';
import SpeakerAvatar from '@/views/apps/people/components/SpeakerAvatar.vue';
import EventsHorizontalList from '@/views/apps/events/components/EventsHorizontalList.vue';
import CollectiveLogo from '@core/components/collective-logo/CollectiveLogo.vue';
import { checkPermissions } from '@/@core/utils/roles-utils';
import { EVENTS_ACTIONS } from '@/views/apps/events/constants/events-store-constants';
import Service from '@/config/service-identifiers';
import OrderBy from '@/@core/constants/OrderBy';
import LogoPlaceholder from '@/assets/images/placeholders/light/icono-general-light.png';
import ToastNotificationsMixin from '@core/mixins/toast-notifications/ToastNotificationsMixin';
import vSelect from 'vue-select';
import placeholder from '@/assets/images/placeholders/light/people.svg';
import { getImageResource } from '@/@core/utils/image-utils';
import UnshareModal from '@/@core/components/modal/UnshareModal.vue';
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue';
import { quillEditor } from 'vue-quill-editor';

export default {
  components: {
    EventsHorizontalList,
    vSelect,
    UnshareModal,
    quillEditor,
  },
  mixins: [ToastNotificationsMixin],
  props: {
    speaker: {
      type: Object,
      required: true,
    },
    type: {
      type: Object,
    },
  },
  data() {
    return {
      isVisible: false,
      eventsNumber: null,
      person: {
        name: '',
        surname: '',
        typeKey: '',
        published: false,
        emails: [],
        jobTitle: {},
        organization: '',
        description: {
          en: '',
          es: '',
          ca: '',
        },
      },
      noKey: null,
      imageURL: null,
      organizationLogoURL: null,
      logoSrc: '',
      avatarSrc: '',
      types: [],
      published: false,
      isUnshareModalVisible: false,
    };
  },
  computed: {
    // canView() {
    //   return checkPermissions('view', 'event', this.loggedMemberRoles, this.currentCollective);
    // },
    locale() {
      return this.$store.getters.currentLocale;
    },
    headline() {
      return translateTranslationTable(this.locale, this.speaker.headline);
    },
    jobTitle() {
      return translateTranslationTable(this.locale, this.speaker.jobTitle);
    },
    organization() {
      return this.speaker.organization || '';
    },
    description() {
      return translateTranslationTable(this.locale, this.speaker.description);
    },
    translatedType() {
      return translateTranslationTable(this.locale, this.speaker.type.name);
    },
    isStaff() {
      return this.$store.getters.currentCollective.isStaff;
    },
    placeholder() {
      return placeholder;
    },
    logoPlaceholder() {
      return LogoPlaceholder;
    },
  },
  watch: {
    organizationLogoURL(newValue, oldValue) {
      if (newValue !== oldValue) {
        if (newValue) {
          this.base64Encode(newValue)
            .then((value) => {
              this.logoSrc = value;
            })
            .catch(() => {
              this.logoSrc = null;
            });
        } else {
          this.logoSrc = null;
        }
      }
    },
    imageURL(newValue, oldValue) {
      if (newValue !== oldValue) {
        if (newValue) {
          this.base64Encode(newValue)
            .then((value) => {
              this.avatarSrc = value;
            })
            .catch(() => {
              this.avatarSrc = null;
            });
        } else {
          this.avatarSrc = null;
        }
      }
    },
  },
  created() {
    this.getEvents();
    this.person = this.speaker;
    if (this.person.emails.length === 0) {
      this.person.emails.push({ email: '', label: 'Email' });
    }
    this.avatarSrc = getImageResource(
      this.speaker.avatarURL || this.speaker.imageURL,
    );
    this.logoSrc = getImageResource(this.speaker.organizationLogoURL);
    this.fetchTypes();
    this.published = this.speaker.isPublished;
  },
  methods: {
    base64Encode(data) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(data);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
    async fetchTypes() {
      this.$store.getters.types?.AllPeopleTypes?.unpaginated.map((item) => this.types.push({
        name: item.name[this.locale] || Object.values(item.name)[0],
        key: item.key,
      }));
      // this.person.typeKey = this.type.key;
      this.noKey = this.translatedType;
    },
    fetchMoreItems(itemType) {
      this.$store.dispatch('getItemsNode', { itemType });
    },
    handleItemsLoaded(response) {
      if (response?.data?.peoples) {
        this.isVisible = response?.data?.peoples.some(
          ({ key }) => key === this.speaker.key,
        );
      }
    },
    hasSocialIcon(site) {
      // Social media icons: Instagram, Facebook, Linkedin, Youtube. The rest with globe icon
      // Social media names obtained from @copernicsw/community-common/link/labelType
      return ['Instagram', 'Facebook', 'Linkedin', 'Youtube'].includes(site);
    },
    async getEvents() {
      const response = await this.$store.dispatch(
        EVENTS_ACTIONS.fetchEventList,
        {
          peopleKey: this.speaker.key,
        },
      );
      this.eventsNumber = response?.data?.data.length;
    },
    async savePeople() {
      const images = {};
      if (this.organizationLogoURL != null) {
        images.organizationLogoURL = this.organizationLogoURL;
      }
      if (this.imageURL != null) {
        images.imageURL = this.imageURL;
      }
      const settings = {
        itemType: 'people',
        /*         storedKey: this.type.key, */
        requestConfig: {
          name: this.person.name,
          surname: this.person.surname,
          typeKey: this.noKey.key,
          published: this.published ? 1 : 0,
          emails: this.person.emails,
          jobTitle: this.person.jobTitle,
          organization: this.person.organization,
          peopleKey: this.speaker.key,
          description: this.person.description,
        },
      };
      try {
        await this.$store.dispatch('editItem', {
          item: settings,
          file: images,
          forceUpdate: true,
        });

        this.notifySuccess(
          this.$t('people.messages.success', { type: this.translatedType }),
        );
        this.$emit('load-items');
        this.speaker.isPublished = this.published;
      } catch {
        this.notifyError(
          this.$t('people.messages.error', { type: this.translatedType }),
        );
      }
    },
    async handleRemoveItem(executeDelete) {
      if (executeDelete === true) {
        try {
          await this.$store.dispatch('unshareItem', {
            item: {
              itemType: 'people',
              key: this.speaker.key,
              storedKey: this.speaker.type.key,
            },
            noSet: true,
          });

          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: this.$t('unshare.toast-messages.success', {
                item: this.$t('unshare.singular-items.people'),
              }),
              icon: 'CheckIcon',
              variant: 'success',
            },
          });
          this.$emit('load-items');
          this.$emit('close-modal', this.speaker.key);
        } catch {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: this.$t('unshare.toast-messages.error', {
                item: this.$t('unshare.singular-items.people'),
              }),
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          });
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@core/scss/base/pages/page-profile.scss";
@import "~@core/scss/base/bootstrap-include";
.social-link {
  margin: 0.5rem;
}
.modal-avatar {
  width: auto;
  height: 14rem;
}
@include media-breakpoint-up(md) {
  .modal-avatar {
    width: 14rem;
  }
}
.avatar {
  width: 130px !important;
  height: 130px !important;
  object-fit: cover;
      object-position: top;
      vertical-align: top;
  border-radius: 50%;
  background: white;
}
.logo {
  width: 100px !important;
  height: 100px !important;
  object-fit: contain;
  border-radius: 50%;
  background: white;
}
</style>
