<template>
  <div
    class="speaker-avatar"
    :class="fluid ? 'speaker-avatar--fluid' : 'speaker-avatar--fix'"
  >
    <safe-img
      :src="speaker.avatarURL"
      :alt="`${speaker.name} ${speaker.surname}`"
      :placeholder="placeholder"
      class="speaker-avatar__img d-block"
      :style="!speaker.isPublished ? 'filter: grayscale(100%);' : ''"
      :retry="1000"
    />
  </div>
</template>

<script>
import PeoplePlaceholder from '@/assets/images/placeholders/light/people.svg';
import SafeImg from '@core/components/safe-img/SafeImg.vue';
import { getImageResource } from '@/@core/utils/image-utils';

export default {
  name: 'SpeakerAvatar',
  components: { SafeImg },
  props: {
    speaker: {
      type: Object,
      required: true,
    },
    fluid: Boolean,
  },
  computed: {
    computedAvatar() {
      return getImageResource(this.speaker.avatarURL);
    },
    placeholder() {
      return PeoplePlaceholder;
    },
  },
};
</script>

<style lang="scss" scoped>
.speaker-avatar {
  margin:0 !important;
  &--fix {
    .speaker-avatar__img {
      object-fit: cover;
      object-position: top;
      width: 100%;
      height: 13rem;
      border-radius: 8px;
    }
  }
  &--fluid {
    height: 0;
    overflow: hidden;
    padding-top: 100%;
    box-sizing: border-box;
    position: relative;
    .speaker-avatar__img {
      width: 100%;
      object-fit: cover;
      object-position: top;
      vertical-align: top;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      border-radius: 8px;
    }
  }
}
</style>
