<template>
  <b-card
    tag="a"
    no-body
    class="speaker-card d-flex flex-column"
    :class="!isDetail ? 'small-card p-1' : 'p-2'"
    :style="!speaker.isPublished ? 'background-color: #f4f4f4' : ''"
    @click="loadSpeakerInfo(speaker)"
  >
    <speaker-avatar :speaker="speaker" :fluid="isDetail" />
    <component :is="isDetail ? 'h4' : 'h5'" class="mt-1 mb-25 text-center people-information clip-content">
      {{ titleSpeaker }}
    </component>

    <!-- eslint-disable vue/no-v-html-->
    <component
      :is="isDetail ? 'b-card-text' : 'h5'"
      :class="isDetail ? 'text-muted text-center mb-25' : 'text-muted text-center mb-25 pb-0'"
      class="clip-content people-information"
      v-html="headline || jobTitle || speaker.organization"
    />
    <b-card-group
      v-if="speaker.organization
      "
      class="d-flex align-items-center justify-content-center text-center flex-nowrap m-0"
    >
      <!--      <b-avatar
        size="16"
        :src="getImageSrc(organizationLogoURL)"
        :alt="speaker.organization"
        class="user-avatar mr-50"
      /> -->

      <b-card-text class="text-muted html-text-ellipsis-1 mb-0 small">
        {{ speaker.organization }}
      </b-card-text>
    </b-card-group>
    <b-modal
      v-model="isModalVisible"
      hide-footer
      centered
      size="lg"
      :title="translatedType"
    >
      <speaker-modal
        :speaker="speaker"
        :type="type"
        @close-modal="closeModal"
        @load-items="loadItems"
      />
    </b-modal>
  </b-card>
</template>

<script>
import SpeakerModal from '@core/components/modal/SpeakerModal.vue';
import { translateTranslationTable } from '@core/libs/i18n/utils';
import { getImageResource } from '@/@core/utils/image-utils';
import SpeakerAvatar from './SpeakerAvatar.vue';

export default {
  name: 'SpeakerCard',
  components: {
    SpeakerModal,
    SpeakerAvatar,
  },
  props: {
    type: {
      type: Object,
    },
    speaker: {
      type: Object,
      required: true,
    },
    isDetail: Boolean,
  },
  data() {
    return {
      namePage: '',
      isModalVisible: false,
    };
  },
  computed: {
    locale() {
      return this.$store.getters.currentLocale;
    },
    titleSpeaker() {
      return typeof this.speaker.name === 'object'
        ? translateTranslationTable(this.locale, this.speaker.name)
        : `${this.speaker.name} ${this.speaker.surname}`;
    },
    jobTitle() {
      return translateTranslationTable(this.locale, this.speaker.jobTitle) || '';
    },
    headline() {
      return translateTranslationTable(this.locale, this.speaker.headline) || '';
    },
    translatedType() {
      return translateTranslationTable(this.locale, this.speaker.type.name);
    },

  },
  methods: {
    loadItems() {
      this.$emit('fetch-items');
    },
    closeModal(key) {
      this.isModalVisible = false;
      this.$emit('load-items', key);
    },
    loadSpeakerInfo() {
      this.isModalVisible = true;
    },
    getImageSrc(image) {
      getImageResource(image);
    },
  },
};
</script>

<style lang="scss" scoped>
.speaker-card {
  height: 100%;
  min-height: 407px;
  margin-top: 0;
  margin-bottom: 0;
}
.small-card{
   min-height: 290px;

}
.speaker-avatar {
  margin: auto;
  display: block;
}
.clip-content {
  text-overflow: ellipsis;
  line-height: 1.2;
  max-height: 2.4rem;
  overflow: hidden;
}
</style>
